import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { AccountUser } from 'state-domains/domain/subscription';
import { buildConfigurationUserRoleUrl } from 'state-domains/network';

import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const modifyAdminPrivileges = (payload: any): Observable<AccountUser> => {
    const url = buildConfigurationUserRoleUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<AccountUser>) =>
            observableOf(convertToCamel<AccountUser>(response)),
        ),
    );
};
